import isEmail from 'validator/lib/isEmail';
import { formatCurrency } from '.';

export const passwordRegExp = value =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#^])[A-Za-z0-9@$!%*?&#^]+$/.test(value)
    ? undefined
    : 'Password must be at least 8 characters in length and contain a lowercase, uppercase, digit and special character (!@#$%^&)';

export const required = value => {
  if (typeof value === 'string') {
    value.trim();
  }
  return value ? undefined : 'Required';
};

export const validateEmail = value => (!value || isEmail(value) ? undefined : "Email isn't valid");

export const minLength = min => (value = '') =>
  !value || value.length >= min ? undefined : `Must have a length at least ${min} characters`;
export const maxLength = max => (value = '') =>
  !value || value.length <= max ? undefined : `Must have a length less than ${max} characters`;

export const minValue = minAmount => (value = '') => {
  const parsed = Number(value);
  if (Number.isNaN(parsed) || parsed <= 0) return `Counter should be positive number`;
  if (!minAmount) return;
  if (parsed < minAmount) return `Counter should be higher or equal to ${formatCurrency(minAmount)}`;
};

export const maxValue = (maxAmount, user = null) => (value = '') => {
  const parsed = Number(value);
  if (user && user.role === 'admin') return;
  if (Number.isNaN(parsed) || parsed <= 0) return `Counter should be positive number`;
  if (!maxAmount) return;
  if (parsed >= maxAmount) return `Counter should be less than ${formatCurrency(maxAmount)}`;
};

export const divisibleBy = (amount = 100) => (value = '') => {
  const parsed = Number(value);
  if (Number.isNaN(parsed) || parsed <= 0) return `Counter should be positive number`;
  if (parsed % amount !== 0) return `Counter should be divisible by ${amount}`;
};

export const isSame = (field, message) => (value, values) =>
  values[field] === value ? undefined : message || `Must be same as ${field}`;

export const vinLength = value => (value.length === 17 ? undefined : 'Length must be 17 symbols');

export const dashValidation = (value = '') => {
  if (!value || value === '') return;
  if (!/^[0-9 -]+$/.test(value)) {
    return 'Only numbers and “-“ is acceptable';
  }
};

export const validateDoc = value => {
  if (!value || value.length === 0) {
    return undefined;
  }
  const [file] = value;

  const { type } = file;
  const allowedMimeTypes = ['image/png', 'image/jpeg', 'application/pdf', 'msword/doc'];

  return allowedMimeTypes.includes(type) ? undefined : 'Wrong file type please attach image or pdf file';
};

export const phoneNumberValidation = value =>
  value && value.replace(/[\D]/g, '').length === 10 ? undefined : 'Phone number must contain 10 digits';

export const phoneNumberNotRequiredValidation = value => {
  if (!value) return undefined;
  return value && value.replace(/[\D]/g, '').length === 10 ? undefined : 'Phone number must contain 10 digits';
};

export const validateMinBid = (value, formValues, zxc) => {
  if (value == null || value === '') return undefined;
  const parsed = typeof value === 'number' ? value : Number(value.trim());
  const currentReservePrice = formValues.reserve_price ? Number(formValues.reserve_price) : null;
  if (currentReservePrice && parsed >= currentReservePrice) return 'Starting bid should be less than reserve price';
  if (!parsed || parsed <= 0 || parsed % 100 !== 0) return 'Starting bid should be dividable by 100';
  return undefined;
};

export const validateReservePrice = (value, formValues) => {
  if (value == null || value === '') return undefined;
  const parsed = typeof value === 'number' ? value : Number(value.trim());
  const currentStartingBid = formValues.starting_bid ? Number(formValues.starting_bid) : null;

  if (parsed <= 100) return 'Reserve price should be higher than starting bid';
  if (currentStartingBid && parsed <= currentStartingBid) return 'Reserve price should be higher than starting bid';

  return undefined;
};

export default {
  required,
  minLength,
  maxLength,
  validateEmail,
  vinLength,
  passwordRegExp,
  isSame,
  validateDoc,
  phoneNumberValidation
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
