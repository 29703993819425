import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const StyledImage = styled.img`
  width: 120px;
  height: 96px;
  border-radius: 3px;
  cursor: pointer;
`;

const ImagePreview = ({ url, vehicleId, history }) => (
  <StyledImage
    src={url}
    onClick={() => history.push(`/vehicles/${vehicleId}/details`)}
  />
);

export default withRouter(ImagePreview);
