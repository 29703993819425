import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import {
  VehicleSales,
  VehicleSalesHistory,
  VehicleList,
  VehicleAdd,
  Invoices as SellerInvoices,
  VehicleBuyerDocs
} from './Seller';
import VehicleEdit from './Seller/VehicleAdd/VehicleEdit';
import Layout from '../components/Layout';
import Home from './Home';

import {
  LocationsList,
  AuctionsList,
  AuctionDetail,
  CustomersList,
  CustomerDetailPage,
  BuyerFeesPage,
  BuyersDocs,
  VehiclesList,
  DeclinedCardsPage,
  AdditionalFeesPage,
  BannersList,
  SiteStatistics,
  SiteStatisticsDetails,
  TransactionsList,
  VehicleManualUpload,
  Logs,
  AuctionSummaryReportPage,
  ListerSummaryReportPage,
  AccountsList,
  AccountLogsList,
  AdminSellers,
  AuctionSummaryReportExportPage
} from './Admin';
import { Invoices as AdminFinancialsInvoices, PastInvoices as AdminFinancialsPastInvoices } from './Admin/Financials';
import { TransactionsList as BuyerTransactionsList, BidsList, BidsHistory, EasyPayPurchase } from './Buyer';
import {
  AutocheckReport,
  Profile,
  FaqsPage,
  ContactsPage,
  AboutPage,
  TermsPage,
  PrivacyPage,
  FundingNotice
} from './Shared';
import { roles } from '../constants';
import RoleRestrictedRoute from '../components/RoleRestrictedRoute';
import { Registration, Login, ForgotPassword, ResetPassword, SuccessPage } from './Auth';
import { removeForm } from '../utils/vehicleForm';
// import AuctionSummaryReportExportPage from './Shared/AucttionSummaryReportExport';
import { LoaderComponent } from 'components';
import { LoadingSplashScreen } from './Splashscreens/Loading';
import { StartScreen } from './Splashscreens/StartScreen';
import ListerDashboard from './Admin/ListerDashboard';
import ListerMap from './Admin/ListerMap';

const LiveAuction = lazy(() => import('./LiveAuction'));
const NewFeatures = lazy(() => import('./NewFeatures'));
const VehicleDetails = lazy(() => import('./Shared/VehicleDetails'));

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location && prevProps.location && location.pathname !== prevProps.location.pathname) {
      removeForm();
    }
  }

  render() {
    return (
      <Layout {...this.props}>
        <Suspense fallback={LoaderComponent}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/new-features" component={NewFeatures} />
            <Route exact path="/live-auction" component={LiveAuction} />
            <Route exact path="/register" component={Registration} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset/:token" component={ResetPassword} />
            <Route exact path="/success/:page" component={SuccessPage} />
            <Route exact path="/faqs" component={FaqsPage} />
            <Route exact path="/contact-us" component={ContactsPage} />
            <Route exact path="/about-us" component={AboutPage} />
            <Route exact path="/terms-of-use" component={TermsPage} />
            <Route exact path="/privacy-policy" component={PrivacyPage} />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN, roles.SELLER]}
              path="/vehicles/add"
              component={VehicleAdd}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN, roles.SELLER]}
              path="/vehicles/add-manual"
              component={VehicleManualUpload}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN, roles.SELLER]}
              path="/vehicles/:vehicleId/edit-manual"
              component={VehicleManualUpload}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.SELLER, roles.ADMIN]}
              path="/vehicles/:vehicleId/buyer-docs"
              component={VehicleBuyerDocs}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN, roles.SELLER]}
              path="/vehicles/:vehicleId/edit"
              component={props => <VehicleEdit edit {...props} />}
            />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/vehicles" component={VehiclesList} />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/locations" component={LocationsList} />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/auctions" component={AuctionsList} />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/banners" component={BannersList} />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/site-statistics"
              component={SiteStatistics}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/site-statistics/:id"
              component={SiteStatisticsDetails}
            />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/auctions/:id" component={AuctionDetail} />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/invoices"
              component={AdminFinancialsInvoices}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/invoices/:sellerId"
              component={AdminFinancialsPastInvoices}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/buyer-fees"
              component={BuyerFeesPage}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/declined-cards"
              component={DeclinedCardsPage}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/additional-fees"
              component={AdditionalFeesPage}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/transactions"
              component={TransactionsList}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/auction-summary-report"
              component={AuctionSummaryReportPage}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/auction-summary-report/export"
              component={AuctionSummaryReportExportPage}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/financials/lister-summary-report"
              component={ListerSummaryReportPage}
            />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/customers" component={CustomersList} />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/accounts" component={AccountsList} />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/account-logs" component={AccountLogsList} />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/my-sellers" component={AdminSellers} />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} path="/admin/logs" component={Logs} />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/customers/:customerId"
              component={CustomerDetailPage}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN]}
              path="/admin/customers/:id/docs"
              component={BuyersDocs}
            />

            <RoleRestrictedRoute exact allowed={[roles.BUYER]} path="/buyer/bids" component={BidsList} />
            <RoleRestrictedRoute exact allowed={[roles.BUYER]} path="/buyer/bids-history" component={BidsHistory} />
            <RoleRestrictedRoute
              exact
              allowed={[roles.BUYER]}
              path="/buyer/transactions"
              component={BuyerTransactionsList}
            />

            <RoleRestrictedRoute exact allowed={[roles.SELLER]} path="/seller/vehicles" component={VehicleList} />
            <RoleRestrictedRoute exact allowed={[roles.SELLER]} path="/seller/dashboard" component={VehicleSales} />
            <RoleRestrictedRoute
              exact
              allowed={[roles.SELLER]}
              path="/seller/vehicle-sales/history"
              component={VehicleSalesHistory}
            />
            <RoleRestrictedRoute exact allowed={[roles.SELLER, roles.BUYER]} path="/profile/:tab" component={Profile} />
            <RoleRestrictedRoute exact allowed={[roles.SELLER]} path="/seller/invoices" component={SellerInvoices} />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN, roles.SELLER, roles.BUYER]}
              path="/vehicles/:vehicleId/details"
              component={VehicleDetails}
            />
            <RoleRestrictedRoute
              exact
              allowed={[roles.ADMIN, roles.SELLER, roles.BUYER]}
              path="/vehicles/:vin/autocheck-report"
              component={AutocheckReport}
            />
            <Route exact path="/vehicles/:id/funding-notice" component={FundingNotice} />
            <RoleRestrictedRoute
              exact
              allowed={[roles.BUYER]}
              path="/buyer/bids/:vehicleId/easy-pay"
              component={EasyPayPurchase}
            />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} component={ListerDashboard} path="/lister/dashboard" />
            <RoleRestrictedRoute exact allowed={[roles.ADMIN]} component={ListerMap} path="/lister/map" />
            <Route path="/unauthorized" component={() => <h2>Unauthorized</h2>} />
          </Switch>
        </Suspense>
      </Layout>
    );
  }
}

export default withRouter(App);
