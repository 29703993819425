import { createEntityAdapter } from '@reduxjs/toolkit';
import { Vehicle } from '../../../types/vehicle';
import apiSlice from '..';
import { EntityWithCount, SocketMessage } from '../types';
import {
  WS_MY_BID_UPDATE,
  WS_NEW_BID,
  WS_NEW_PROXY,
  WS_VEHICLE_DETAILS_UPDATE,
  WS_VEHICLE_NOTES_UPDATE,
  WS_VEHICLE_TIME_END
} from '../../../constants/actionTypes';
import getSocket from '../../socket';
import { VehicleSocketEventHandlerManager } from '../socketHelpers';
import moment from 'moment';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';
import { convertToFormData } from '../../../utils/api';
import { User } from '../../../types/user';

type MyBidsQueryArgs = {
  [x: string]: any;
};

export const bidsAdapter = createEntityAdapter<Vehicle>({
  // sortComparer: (a, b) => moment(a.date_end).diff(b.date_end)
});

export const sidePanelBidsAdapter = createEntityAdapter<Vehicle>({
  sortComparer: (a, b) => moment(a.date_end).diff(b.date_end)
});

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<any, any>({
      query: body => ({
        url: '/auth/login',
        method: 'POST',
        body
      }),
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    forgotPassword: builder.mutation<any, any>({
      query: body => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body
      }),
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    register: builder.mutation<any, any>({
      query: body => {
        const formData = convertToFormData(body);
        return {
          url: '/auth/register',
          method: 'POST',
          body: formData,
          formData: true
        };
      },
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    onboarding: builder.mutation<any, any>({
      query: body => {
        const formData = convertToFormData(body);
        return {
          url: '/auth/onboarding',
          method: 'POST',
          body: formData,
          formData: true
        };
      },
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    me: builder.query<User, void>({
      query: () => ({
        url: '/me'
      }),
      providesTags: ['Me'],
      transformResponse: (r: any) => r.data
    }),
    validateForm: builder.mutation<any, any>({
      query: body => ({
        method: 'POST',
        url: 'auth/validate',
        body
      }),
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    })
  }),
  overrideExisting: true
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useRegisterMutation,
  useOnboardingMutation,
  useMeQuery,
  useValidateFormMutation
} = authApiSlice;
