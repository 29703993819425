import React from 'react';
import { RadioGroup, FormHelperText } from '@material-ui/core';

const FormRadioGroup = ({
  input: { onChange, value, ...inputProps },
  meta: { touched, invalid, error },
  onChange: onChangeFromField,
  ...props
}) => (
  <>
    <RadioGroup
      {...inputProps}
      {...props}
      value={value}
      onChange={(event, inputValue) => {
        onChange(inputValue);
        if (onChangeFromField) {
          onChangeFromField(inputValue);
        }
      }}
    />
    {touched && invalid && error && (
      <FormHelperText error>{error}</FormHelperText>
    )}
  </>
);

export default FormRadioGroup;
