import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { NoImagePlaceholder } from '../../index';

const StyledImage = styled.img`
  width: 96px;
  height: 72px;
  border-radius: 3px;
  object-fit: cover;
  cursor: pointer;
`;

const VehicleImage = ({ id, history, images }) => {
  const [image] = images;

  if (image && image.url_thumb) {
    return (
      <StyledImage
        src={image.url_thumb}
        onClick={() => history.push(`/vehicles/${id}/details`)}
      />
    );
  }

  return <NoImagePlaceholder />;
};

export default withRouter(VehicleImage);
