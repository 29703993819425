import { Button, Icon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  gap: 16px;
  display: flex;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: center;
`;

const StyledManualUploadButton = styled.div`
  height: 44px;
  width: 100%;
  border-radius: 4px;
  background: #225a91;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: #005a96;
  }
`;

const StyledAutomaticUploadButton = styled.div`
  border: 1px solid #999999;
  height: 44px;
  width: 100%;
  border-radius: 4px;
  color: #999999;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: #b0b0b04d;
  }
`;

const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 15.575C11.8667 15.575 11.7417 15.5542 11.625 15.5125C11.5083 15.4708 11.4 15.4 11.3 15.3L7.7 11.7C7.5 11.5 7.40417 11.2667 7.4125 11C7.42083 10.7333 7.51667 10.5 7.7 10.3C7.9 10.1 8.1375 9.99583 8.4125 9.9875C8.6875 9.97917 8.925 10.075 9.125 10.275L11 12.15V5C11 4.71667 11.0958 4.47917 11.2875 4.2875C11.4792 4.09583 11.7167 4 12 4C12.2833 4 12.5208 4.09583 12.7125 4.2875C12.9042 4.47917 13 4.71667 13 5V12.15L14.875 10.275C15.075 10.075 15.3125 9.97917 15.5875 9.9875C15.8625 9.99583 16.1 10.1 16.3 10.3C16.4833 10.5 16.5792 10.7333 16.5875 11C16.5958 11.2667 16.5 11.5 16.3 11.7L12.7 15.3C12.6 15.4 12.4917 15.4708 12.375 15.5125C12.2583 15.5542 12.1333 15.575 12 15.575ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V16C4 15.7167 4.09583 15.4792 4.2875 15.2875C4.47917 15.0958 4.71667 15 5 15C5.28333 15 5.52083 15.0958 5.7125 15.2875C5.90417 15.4792 6 15.7167 6 16V18H18V16C18 15.7167 18.0958 15.4792 18.2875 15.2875C18.4792 15.0958 18.7167 15 19 15C19.2833 15 19.5208 15.0958 19.7125 15.2875C19.9042 15.4792 20 15.7167 20 16V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
      fill="#999999"
    />
  </svg>
);

export const MobileActionButtons = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <StyledManualUploadButton onClick={() => history.push('/vehicles/add-manual')}>
        MANUAL UPLOAD
      </StyledManualUploadButton>
      <StyledAutomaticUploadButton onClick={() => history.push('/vehicles/add')}>
        <Icon>
          <DownloadIcon />
        </Icon>
        <span>UPLOAD VEHICLE</span>
      </StyledAutomaticUploadButton>
    </Wrapper>
  );
};
