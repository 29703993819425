import { User } from './user';

export type Vehicle = {
  id: number;
  [x: string]: any;
};

export enum AuctionType {
  AUTOAXESS = 'autoaxess',
  FRONTLINE = 'frontline'
}

export type Auction = {
  id: number;
  location_id: number;
  type: AuctionType;
  status: string;
  date_end: string;
};

export enum OfferAuctionStatus {
  ENDED = 'ended',
  PRIVATE_BIDS = 'private_bids',
  PUBLIC_OFFERS = 'public_offers'
}

export enum OfferStatus {
  SENT = 'sent',
  SELLER_ACCEPTED = 'seller_accepted',
  ADMIN_ACCEPTED = 'admin_accepted',
  REJECTED = 'rejected',
  COUNTERED = 'countered',
  ADMIN_COUNTERED = 'admin_countered',
  PUBLIC = 'public'
}

export type Offer = {
  id: number;
  offer_auction_id: number;
  sender_id: number;
  sender: User;
  amount: number;
  notes: string;
  status: OfferStatus;
  created_at: string;
  is_admin: boolean;
  type: 'private' | 'public';
  is_negotiation_stopped: boolean;
  edited_at?: string;
  is_edited: boolean;
};

export type OfferAuctionBid = {
  id: number;
  amount: number;
  user_id: number;
  bidder: User;
  status: string;
  created_at: string;
  updated_at: string;
};

export type OfferAuction = {
  id: number; // same as vehicle_id
  status: OfferAuctionStatus;
  top_bidder_id: number;
  vehicle_id: number;
  privateOffers: Offer[];
  created_at: string;
  starting_bid: number;
  offerBids: OfferAuctionBid[];
  offerProxyBid?: OfferAuctionBid;
};
