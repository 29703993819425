import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { cutTextElipsis } from '../../../../utils';

const StyledBidderAccount = styled.p`
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
`;

const HighestBidder = ({ highestBid }) => {
  return (
    <Wrapper>
      <NumberFormat value={highestBid?.amount || 0} displayType="text" thousandSeparator prefix="$" />
      {(highestBid?.amount || 0) !== 0 && (
        <>
          <span>/</span>
          <StyledBidderAccount>{cutTextElipsis(highestBid.bidder.dealership_name, 12)}</StyledBidderAccount>
        </>
      )}
    </Wrapper>
  );
};

export default HighestBidder;
