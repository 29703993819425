import React from 'react';
import { Offer, OfferAuction, OfferStatus } from '../../types/vehicle';
import styled from 'styled-components';
import FormattedAmount from '../FormattedAmount';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';
import { cutTextElipsis } from '../../utils';

type Props = {
  offerAuction: OfferAuction;
  withDivider?: boolean;
  cutNames?: boolean;
  withTopDivider?: boolean;
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.span`
  color: #757575;
  font-size: 18px;
  font-weight: 500;
`;

const BlockWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #b0b0b0;
  background: #f5f5f5;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  max-height: 140px;
  overflow-y: auto;

  /* total width */
  &::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 12px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #f5f5f5;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const OfferWrapper = styled.div`
  padding: 0 12px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const Title = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const Amount = styled.span`
  color: #3994de;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

const Timestamp = styled.span`
  color: #757575;
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`;

const NotesWrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
`;

const AlertsWrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  color: #af2930;
  border: 1px solid #af2930;
  font-size: 14px;
  font-weight: 400;
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

type Alert = {
  amount?: number | null;
  text: string;
  created_at: string | moment.Moment;
  type: 'alert';
};

const formatTimestamp = (timestamp: string | moment.Moment) => {
  return moment(timestamp).format('ddd DD.MM.YY h:mm A');
};

const isAlert = (item: Alert | Offer): item is Alert => {
  return (item as Alert).type === 'alert';
};

const resolveAmount = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(amount);
};

export const OfferHistory = ({
  offerAuction,
  withDivider = false,
  withTopDivider = false,
  cutNames = false
}: Props) => {
  const user = useSelector((state: any) => state.user.user);
  const buyerResponseTime = useSelector(
    (state: any) => state.locations?.currentLocation?.buyer_response_time || 900000
  );
  const sellerResponseTime = useSelector(
    (state: any) => state.locations?.currentLocation?.seller_response_time || 900000
  );

  let privateOffers = offerAuction?.privateOffers?.filter(el => el.type === 'private') ?? [];
  const publicOffers = (() => {
    if (user.role === 'buyer') return [];
    return offerAuction?.privateOffers?.filter(el => el.type === 'public') ?? [];
  })();

  if (privateOffers.length === 0 && publicOffers.length === 0) return null;

  if (user.role === 'buyer') {
    privateOffers = privateOffers.filter(offer => offer.status !== OfferStatus.ADMIN_COUNTERED);
  }

  const offerBids = offerAuction?.offerBids ?? [];

  const rejectedAlerts: Alert[] = (() => {
    const lastOffer = privateOffers[0];
    if (!lastOffer || lastOffer.status !== OfferStatus.REJECTED) return [];

    const buyerRejectedOfferText = ({
      buyer: `You've missed your chance negotiating as Top Buyer. You can still continue making offers with other buyers.`,
      admin: `Buyer is not responding. Take any action to sell this car.`,
      seller: `Buyer is not responding. Our Team is working on it.`
    } as Record<string, string>)[user.role];

    const hasAdminPrivateCounter = privateOffers.some(el => el.is_admin);
    const sellerRejectedOfferText = ({
      buyer: `Seller is not responding. Our Team is working on it. You can still continue making offers with other buyers.`,
      admin: hasAdminPrivateCounter
        ? `Seller is not responding. The counter was sent to all buyers`
        : `Seller is not responding. Take any action to sell this car.`,
      seller: `You've missed your chance to negotiate with the Top Buyer. You can still receive offers from other buyers.`
    } as Record<string, string>)[user.role];

    const responseTime = lastOffer.sender.role === 'buyer' ? sellerResponseTime : buyerResponseTime;
    const text = lastOffer.sender.role === 'buyer' ? sellerRejectedOfferText : buyerRejectedOfferText;

    const amount = (() => {
      if (lastOffer.sender.role !== 'buyer' || !hasAdminPrivateCounter || publicOffers.length === 0) return null;

      return publicOffers.reverse().find(el => el.is_admin)?.amount || null;
    })();

    return [
      {
        amount,
        text,
        created_at: moment(lastOffer.created_at).add(responseTime, 'milliseconds'),
        type: 'alert'
      }
    ];
  })();

  const offerAcceptedAlerts: Alert[] = (() => {
    if (user.role === 'buyer') return [];
    const sellerAcceptedOffer = offerBids.find(el => el.status === 'seller_accepted');
    if (!sellerAcceptedOffer) return [];

    const acceptedOfferText = ({
      admin: `Seller accepted the top offer.`,
      seller: `You've accepted the top offer. Admin is reviewing it. You will get the updates shortly.`
    } as Record<string, string>)[user.role];

    return [{ text: acceptedOfferText, created_at: sellerAcceptedOffer.updated_at, type: 'alert' }];
  })();

  const negotiationStoppedAlerts: Alert[] = (() => {
    const negotiationStoppedOffer = offerAuction?.privateOffers?.find(el => el.is_admin && el.is_negotiation_stopped);
    if (!negotiationStoppedOffer || user.role !== 'admin') return [];

    const negotiationStoppedAlertText = ({
      admin: `AutoAxess stopped countering.`,
      seller: `AutoAxess stopped countering.`,
      buyer: 'AutoAxess stopped countering.'
    } as Record<string, string>)[user.role];

    return [
      {
        text: negotiationStoppedAlertText,
        created_at: negotiationStoppedOffer.created_at, // moment(negotiationStoppedOffer.created_at).subtract(-1, 'milliseconds'),
        type: 'alert'
      }
    ];
  })();

  const offerEditedAlerts: Alert[] = (() => {
    const editedOffers = offerAuction?.privateOffers?.filter(el => el.is_edited);
    return editedOffers
      .map<Alert[]>(offer => {
        return [
          {
            text: `New offer sum ${resolveAmount(offer.amount)}.`,
            created_at: offer.edited_at!,
            type: 'alert'
          },
          {
            text: `AutoAxess changed the offer.`,
            created_at: offer.edited_at!,
            type: 'alert'
          }
        ];
      })
      .flat();
  })();

  const items = [
    ...privateOffers,
    ...publicOffers,
    ...negotiationStoppedAlerts,
    ...offerAcceptedAlerts,
    ...rejectedAlerts,
    ...offerEditedAlerts
  ].sort((a, b) => moment(b.created_at).diff(a.created_at));

  const OfferComponent = ({ offer }: { offer: Offer }) => (
    <OfferWrapper>
      <Header>
        <TitleWrapper>
          <Title>
            {offer.is_admin && user.role !== 'buyer'
              ? 'AutoAxess'
              : cutTextElipsis(offer.sender.dealership_name, cutNames ? 15 : 100)}
          </Title>
          <Amount>
            <FormattedAmount amount={offer.amount} />
          </Amount>
        </TitleWrapper>
        <Timestamp>{formatTimestamp(offer.created_at)}</Timestamp>
      </Header>
      <NotesWrapper>
        <span>{offer.notes}</span>
      </NotesWrapper>
    </OfferWrapper>
  );

  const AlertComponent = ({ alert }: { alert: Alert }) => (
    <OfferWrapper>
      <Header>
        <TitleWrapper>
          <Title>{'System Alert'}</Title>
          {alert.amount && (
            <Amount>
              <FormattedAmount amount={alert.amount} />
            </Amount>
          )}
        </TitleWrapper>
        <Timestamp>{formatTimestamp(alert.created_at)}</Timestamp>
      </Header>
      <AlertsWrapper>
        <span>{alert.text}</span>
      </AlertsWrapper>
    </OfferWrapper>
  );

  return (
    <>
      {withTopDivider && (
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
      )}
      <PageWrapper>
        <PageHeaderWrapper>
          <HeaderTitle>Notes</HeaderTitle>
        </PageHeaderWrapper>
        <BlockWrapper>
          {items.map((item, i) =>
            isAlert(item) ? <AlertComponent alert={item} key={i} /> : <OfferComponent offer={item} key={i} />
          )}
        </BlockWrapper>
      </PageWrapper>
      {withDivider && (
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
      )}
    </>
  );
};
