import { createEntityAdapter } from '@reduxjs/toolkit';
import { Auction, AuctionType } from '../../../types/vehicle';
import { EntityWithCount } from '../types';
import moment from 'moment';
import apiSlice from '..';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';
import { getAuctionById, snackShow } from '../../../actions';

export const auctionsListAdapter = createEntityAdapter<Auction>({});

export const auctionListsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAuctions: builder.query<EntityWithCount<Auction>, Record<string, any>>({
      providesTags: ['AuctionsList'],
      query: ({ limit, offset, auctionType, search }) => ({
        url: `/auctions`,
        method: 'GET',
        params: {
          limit,
          offset,
          auctionType,
          searchDate: search
        }
      }),
      transformResponse: (response: any) => {
        return {
          rows: auctionsListAdapter.addMany(auctionsListAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count)
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg }) => {
        const newAuctions = (newItems.rows?.ids?.map(el => newItems.rows.entities[el]) ?? []) as Auction[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          auctionsListAdapter.setAll(currentCache.rows, newAuctions);
        } else {
          auctionsListAdapter.addMany(currentCache.rows, newAuctions);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    }),
    createAuction: builder.mutation<void, { startDate: string; auctionType: AuctionType }>({
      invalidatesTags: ['AuctionsList'],
      query: ({ startDate, auctionType }) => ({
        url: '/auctions',
        method: 'POST',
        body: {
          date_start: startDate,
          location_id: 1,
          auction_type: auctionType
        }
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ message: 'Auction created' }));
        } catch (e) {
          dispatch(
            snackShow({
              message: 'You must wait till auction end, before creating new one',
              type: 'error'
            })
          );
        }
      }
    }),
    getLocation: builder.query<void, any>({
      query: () => ({
        url: '/locations/1',
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
    getAuctionById: builder.query<any, number>({
      query: id => ({
        url: `/auctions/${id}`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
  }),
  overrideExisting: true
});

export const {
  useGetAuctionsQuery,
  useCreateAuctionMutation,
  useGetLocationQuery,
  useGetAuctionByIdQuery
} = auctionListsApi;
export default auctionListsApi;
