import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './index.scss';

const StyledWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1296px;
  padding: 0 12px;
`;

const PageWrapper = ({ className, children }) => (
  <StyledWrapper className={className}>
    {children}
  </StyledWrapper>
);

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.object
  ]).isRequired
};

export default PageWrapper;
