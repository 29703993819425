import { ButtonBase, Card, CardContent, Grid, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import cx from 'classnames';
import { push } from 'connected-react-router';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { initVehicleDetailsData } from '../../actions/vehicles';
import { NotesSection } from '../../components/Notes';
import { BID_INCREMENT, VEHICLE_STATUSES, MOBILE_BREAKPOINT } from '../../constants';
import ArrowLeft from '../../img/Left Arrow.svg';
import ArrowRight from '../../img/Right Arrow.svg';
import NextIcon from '../../img/auction-order/next.svg';
import PriorIcon from '../../img/auction-order/prior.svg';
import { IncreaseHighBid } from '../../pages/Buyer/BidsList/containers';
import Timer from '../Timer';
import { AUTSlider } from '../index';
import InfoElement from './InfoElement';
import { CarListView } from './ListView';
import { getHighestBid, getUserHighestBid, getMinBitAmount } from '../../utils/vehicle';
import './index.scss';
import { CaretDown, CaretUp, MyBidsIcon } from '../../components/Icons';
import { getVehicleTimer } from '../../utils/vehicle';
import { ReservePriceLabel } from 'components/ReservePriceMet';
import { formatCurrency } from 'utils';

const StyledTyporgraphyPerks = styled(Typography)`
  && {
    font-size: 14px;
  }
`;

const TypographyWithMinHeight = styled(Typography)`
  && {
    // min-height: 64px;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MyBidsIconWrapper = styled.div`
  top: 8px;
  left: -4px;
  z-index: 99;
  overflow: visible;
  position: absolute;
`;

const BidInfoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const HoverWrapper = styled.div`
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  margin-top: 30px;
  padding-top: 8px;
  border-top: 1px solid #e5e5e5;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const CaretIconWrapper = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  margin-top: 12px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const StyledButtonWrapper = styled(ButtonBase)`
  position: relative;
  && {
    display: block;
    width: 100%;
    text-align: initial;
  }
`;

const ReserveLabelWrapper = styled.div`
  padding-bottom: 8px;
`;

const ReservePlaceholder = styled.div`
  height: 17px;
  width: 100%;
`;

const BluebookRow = styled.div`
  font-size: 14px;
  color: rgb(97, 97, 97);
  padding-top: 8px;
`;

export class CarCard extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, isExpanded: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  onClick = () => {
    const { redirectToVehiclePage, initVehicleData, vehicle } = this.props;
    redirectToVehiclePage(vehicle.id);
    initVehicleData(vehicle);
  };

  onMouseOver = () => {
    const { listView } = this.props;
    if (listView) return;
    // if (this.width <= 1200) return;
    this.setState({ isExpanded: true });
  };

  onMouseOut = () => {
    // if (this.width <= 1200) return;
    this.setState({ isExpanded: false });
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  toggleExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { width, vehicle, user, withHover } = this.props;
    const isLoggedIn = user && user.id;
    let { listView } = this.props;
    const { isExpanded } = this.state;

    const vehicleTimer = getVehicleTimer(vehicle);
    const canPlaceBid =
      !!user &&
      user.role === 'buyer' &&
      vehicle.status === VEHICLE_STATUSES.ACTIVE &&
      moment(vehicleTimer).isAfter(moment());

    if (['md', 'sm', 'xs'].includes(width)) {
      listView = false;
    }

    const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
    let vehicleImages = vehicle.images;
    if (vehicleImages.length > 3) {
      vehicleImages = vehicleImages.slice(0, 3);
    }
    const highestBid = getHighestBid(vehicle);
    const userHighestBid = getUserHighestBid(vehicle, user?.id);

    const vehicleOptions = (vehicle.options || []).filter(option => option.is_typical).slice(0, 6);

    const iconByType = {
      prior: PriorIcon,
      next: NextIcon
    };

    const minBidAmount = getMinBitAmount(vehicle, user);
    const myBid = vehicle.my_bid;

    const { width: screenWidth } = this.state;

    const isMobile = screenWidth <= MOBILE_BREAKPOINT;
    const hasMyBid = Boolean(vehicle.my_bid);

    const bidComponentTitle = highestBid?.amount ? 'Current bid' : 'Starting/Min Bid';
    const bidComponentAmount = highestBid?.amount || vehicle?.starting_bid || 100;

    const highBidTitle = userHighestBid.isProxy ? 'High Bid (proxy)' : 'High Bid';

    const RenderReservePriceComponent = () => {
      if (user.role !== 'buyer') return null;

      return (
        <ReserveLabelWrapper>
          {vehicle.reserve_price_set ? <ReservePriceLabel size="sm" activeVehicle={vehicle} /> : <ReservePlaceholder />}
        </ReserveLabelWrapper>
      );
    };

    const vehicleBookMileage = `Book: ${formatCurrency(vehicle.bluebook_mileage)}/ miles: ${formatCurrency(
      vehicle.mileage
    ).slice(1)}`;

    return (
      <div style={{ position: 'relative' }}>
        {vehicle.iconType && (
          <div className="label-image">
            <img src={iconByType[vehicle.iconType]} alt={vehicle.iconType} />
          </div>
        )}
        <Card
          square
          elevation={this.raised}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          className={`car-card ${listView ? 'list-view' : ''}`}
          style={{ display: listView ? 'flex' : 'block', flex: 'auto', position: 'relative' }}
        >
          <div
            className="slider-container vehicles-images"
            onClick={this.onClick}
            onKeyPress={e => {
              if (e.target && e.target.className === 'car-card-image') {
                this.onClick();
              }
            }}
            role="button"
            tabIndex="0"
          >
            <AUTSlider
              settings={{
                arrows: true,
                dots: true,
                draggable: false,
                nextArrow: <img src={ArrowRight} alt="next" />,
                prevArrow: <img src={ArrowLeft} alt="prev" />
              }}
            >
              {vehicleImages.map((vehicleImage, i) => (
                <ImageWrapper key={i}>
                  {hasMyBid && (
                    <MyBidsIconWrapper>
                      <MyBidsIcon />
                    </MyBidsIconWrapper>
                  )}
                  <div
                    className={cx('car-card-image', {
                      listView
                    })}
                    style={{ backgroundImage: `url(${vehicleImage.url_thumb})`, gridRow: '1 / 2', gridColumn: '1 / 2' }}
                  />
                </ImageWrapper>
              ))}
            </AUTSlider>
          </div>
          {listView ? (
            <CarListView vehicle={vehicle} />
          ) : (
            <StyledButtonWrapper>
              <CardContent className="lot-info">
                <RenderReservePriceComponent />
                <TypographyWithMinHeight gutterBottom variant="headline" component="h2" title={vehicleName}>
                  {vehicleName}
                </TypographyWithMinHeight>
                <Grid container direction="column" style={{ color: '#616161' }}>
                  {isMobile ? (
                    <BluebookRow>{vehicleBookMileage}</BluebookRow>
                  ) : (
                    <InfoElement isGridView type="miles" item="Miles" value={vehicle.mileage} />
                  )}

                  {isLoggedIn && !isMobile ? (
                    <>
                      <InfoElement nowrap type="miles" item="Value Without Miles Adj" value={vehicle.bluebook_value} />
                      <InfoElement
                        nowrap
                        type="miles"
                        item="Blue Book® Lending Value"
                        value={vehicle.bluebook_mileage}
                      />
                    </>
                  ) : null}
                  <InfoElement isGridView bid type="currency" item={bidComponentTitle} value={bidComponentAmount} />
                  <InfoElement
                    isGridView
                    type="time"
                    item="Time left"
                    value={<Timer onTimerEnded={() => console.log('Timer Enede from CarCard')} toDate={vehicleTimer} />}
                  />

                  {user && user.role === 'buyer' && (
                    <HoverWrapper isExpanded={isExpanded && withHover}>
                      <BidInfoContentWrapper>
                        <InfoElement type="currency" item="Your Bid" value={myBid?.amount ?? '-'} highlighted />
                        <InfoElement
                          type="currency"
                          item={highBidTitle}
                          value={userHighestBid?.amount ?? '-'}
                          highlighted
                        />
                        <NotesSection vehicle={vehicle} />
                      </BidInfoContentWrapper>
                      {canPlaceBid ? (
                        <IncreaseHighBid
                          isSidePanelBid
                          form={`biddingForm_${vehicle.id}`}
                          vehicleId={vehicle.id}
                          minAmount={minBidAmount}
                          bidIncrement={BID_INCREMENT}
                        />
                      ) : null}
                    </HoverWrapper>
                  )}
                  {isMobile && withHover ? (
                    <>
                      <CaretIconWrapper hidden={!isExpanded} onClick={this.toggleExpand}>
                        <CaretUp />
                      </CaretIconWrapper>
                      <CaretIconWrapper hidden={isExpanded} onClick={this.toggleExpand}>
                        <CaretDown />
                      </CaretIconWrapper>
                    </>
                  ) : null}
                </Grid>
              </CardContent>
            </StyledButtonWrapper>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: (state.user || {}).user
});

const mapDispatchToProps = dispatch => ({
  redirectToVehiclePage: vehicleId => dispatch(push(`/vehicles/${vehicleId}/details`)),
  initVehicleData: payload => dispatch(initVehicleDetailsData(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(CarCard));
