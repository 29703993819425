import React from 'react';
import styled from 'styled-components';
import FormattedAmount from '../FormattedAmount';

const CurrentBidWrapper = styled.div<{ isBold: boolean }>`
  background-color: #3994de;
  color: #fff;
  border-radius: 3px;
  padding: 2px;
  display: inline-flex;
  font-weight: ${props => (props.isBold ? '500' : 'inherit')};

  @media (max-width: 600px) {
    padding: 2px;
  }
`;

type Props = {
  amount: number;
  isBold?: boolean;
};

export const CurrentBidComponent = ({ amount, isBold = false }: Props) => (
  <CurrentBidWrapper isBold={isBold}>
    <FormattedAmount amount={amount} />
  </CurrentBidWrapper>
);
