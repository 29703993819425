import React from 'react';
import { Vehicle } from '../../../../types/vehicle';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { initVehicleDetailsData } from '../../../../actions';

const VehicleImage = styled.div<{ imageUrl: string }>`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 90px;
  width: 120px;
  cursor: pointer;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

type Props = {
  vehicle: Vehicle;
};

export const AdminVehicleImage = ({ vehicle }: Props) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(push(`/vehicles/${vehicle.id}/details`));
    dispatch(initVehicleDetailsData(vehicle));
  };

  const vehicleImageSrc = vehicle.images?.[0]?.url ?? '';

  return <VehicleImage imageUrl={vehicleImageSrc} onClick={handleOnClick} />;
};
