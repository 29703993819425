import React from 'react';
import { FormControl, FormHelperText, InputBase } from '@material-ui/core';
import styled from 'styled-components';

const StyledInputBase = styled(InputBase)`
  padding-left: 4px;
`;

const FormTextAreaField = ({
  input,
  input: { value },
  name,
  label = '',
  meta,
  meta: { touched, error, invalid, active },
  customError,
  styleVariant,
  ...custom
}) => {
  const ref = React.useRef(null);

  const styleConfig =
    styleVariant === 'filled'
      ? {
          background: '#f5f5f5'
        }
      : {
          background: 'transparent'
        };

  const [cursor, setCursor] = React.useState(0);
  const updateValue = newValue => input.onChange(newValue);

  const onKeyDown = e => {
    console.log('event code', e.code);
    if (e.code === 'Tab') {
      e.preventDefault();
      const { selectionStart, selectionEnd } = e.target;
      const newValue = value.substring(0, selectionStart) + '\t' + value.substring(selectionEnd);
      updateValue(newValue);
      setCursor(selectionStart + 1);
    }
  };

  // https://stackoverflow.com/questions/60129605/is-javascripts-setselectionrange-incompatible-with-react-hooks
  React.useEffect(() => {
    if (ref.current && cursor) {
      // https://github.com/facebook/react/issues/6483
      // setTimeout because `meta` prop causes additional rerenders
      setTimeout(() => ref.current.setSelectionRange(cursor, cursor), 0);
    }
  }, [cursor]);

  return (
    <FormControl
      name={name}
      helperText={(touched && invalid && error) || customError}
      error={(touched && invalid) || !!customError}
      {...input}
      {...custom}
    >
      <StyledInputBase
        style={{ background: styleConfig.background }}
        onKeyDown={onKeyDown}
        inputRef={ref}
        multiline
        rows={10}
        rowsMax={Infinity}
        placeholder={label}
        label={label}
        error={(touched && invalid) || !!customError}
        {...input}
        {...custom}
      />
      {touched && error && <FormHelperText error={true}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormTextAreaField;
