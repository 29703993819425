import { Vehicle } from '../../../../types/vehicle';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ADMIN_JOB_TITLES, ADMIN_VEHICLE_STATUS, UPLOAD_TYPES, VEHICLE_STATUSES } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { auctionVehiclesSetModal, modalsToggle, pullVehicle } from '../../../../actions';
import {
  finalizeButtonConditionalDisable,
  finalizeButtonConditionalRendering
} from '../../TransactionsList/components/FinalizeSaleModal';
import { TableButton } from '../../../../components/Table/common';
import { useAdminOfferAuction } from '../hooks';
import { auctionOfferStatuses } from '../../../../utils/statusCalculator';
import { openRelistDialog } from '../../../../services/auctionVehicles/auctionVehiclesSlice';
import moment from 'moment';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 8px;
`;

type Props = {
  vehicle: Vehicle;
  bidStatus: string;
};

export const AdminActions = ({ vehicle }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: any) => state.user?.user);

  const isAuctionActive = useSelector(
    (store: any) =>
      store.auctions?.current?.status === 'active' || store.auctions?.currentFrontline?.status === 'active'
  );

  const offerAuction = useAdminOfferAuction(vehicle.id);
  const status = auctionOfferStatuses(vehicle, offerAuction, currentUser);

  const isShuffleTimeRunning = vehicle.date_end && moment(vehicle.date_end).isAfter(moment());

  const buttons = [
    {
      title: 'Edit',
      conditionalRendering: (item: Vehicle) => !!item,
      handleClick: (item: any) =>
        history.push(
          item.upload_type === UPLOAD_TYPES.AUTOMATED ? `/vehicles/${item.id}/edit` : `/vehicles/${item.id}/edit-manual`
        ),
      fullWidth: true,
      order: 1
    },
    {
      title: 'Counter',
      color: 'secondary',
      conditionalRendering: () => {
        return (
          [
            ADMIN_VEHICLE_STATUS.SELLER_COUNTERED,
            ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED,
            ADMIN_VEHICLE_STATUS.UNDECIDED,
            ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT,
            ADMIN_VEHICLE_STATUS.SELLER_COUNTER_SENT,
            ADMIN_VEHICLE_STATUS.ADMIN_COUNTER_SENT,
            ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT,
            ADMIN_VEHICLE_STATUS.BUYER_NOT_COUNTERED,
            ADMIN_VEHICLE_STATUS.PUBLIC_ADMIN_COUNTER_SENT,
            ADMIN_VEHICLE_STATUS.SELLER_NOT_COUNTERED
          ].includes(status) && !vehicle.buyer_id
        );
      },
      order: status === ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED ? 999 : 2,
      handleClick: (item: any) => {
        dispatch(modalsToggle(`sendVehicleOffer-${item.id}`));
      },
      fullWidth: true
    },
    {
      title: 'Finalize',
      color: 'secondary',
      order: 3,
      conditionalRendering: (item: Vehicle) => {
        const isListerOrSalesperson = [ADMIN_JOB_TITLES.SALESMAN, ADMIN_JOB_TITLES.LISTER].includes(
          currentUser?.job_title
        );
        return finalizeButtonConditionalRendering(item) && !isListerOrSalesperson;
      },
      conditionalDisable: (item: Vehicle) => finalizeButtonConditionalDisable(item),
      handleClick: (item: any) => {
        dispatch(modalsToggle('finalizeSaleModal'));
        dispatch(auctionVehiclesSetModal(item));
      },
      fullWidth: true
    },
    {
      title: 'Extend',
      order: 4,
      conditionalRendering: () => {
        return status === ADMIN_VEHICLE_STATUS.IN_PROGRESS && isShuffleTimeRunning;
      },
      // dispatch extend
      handleClick: (item: any) => dispatch(modalsToggle(`extendVehicleTime-${item.id}`)),
      fullWidth: true
    },
    {
      title: 'View',
      order: 5,
      conditionalRendering: () => {
        return (
          [ADMIN_VEHICLE_STATUS.PULLED, ADMIN_VEHICLE_STATUS.DRAFT, ADMIN_VEHICLE_STATUS.DEACTIVATED].includes(
            status
          ) ||
          (status === ADMIN_VEHICLE_STATUS.IN_PROGRESS && !isShuffleTimeRunning)
        );
      },
      handleClick: (item: any) => history.push(`/vehicles/${item.id}/details`),
      fullWidth: true
    },
    {
      title: 'Accept',
      order: 6,
      conditionalRendering: () => status === ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED,
      handleClick: (item: any) => {
        if (vehicle.buyer_id) return dispatch(modalsToggle(`acceptHighBid-${vehicle.id}`));
        dispatch(modalsToggle(`acceptTopOffer-${item.id}`));
      },
      fullWidth: true
    },
    {
      title: 'Pull',
      order: 7,
      conditionalRendering: (item: Vehicle) =>
        VEHICLE_STATUSES.PULLED !== item.status && currentUser?.job_title !== ADMIN_JOB_TITLES.LISTER,
      handleClick: (item: any) => dispatch(modalsToggle(`confirm-pull-${item.id}`)),
      fullWidth: true
    },
    {
      title: 'Relist',
      order: 8,
      conditionalRendering: (item: Vehicle) =>
        VEHICLE_STATUSES.PULLED === item.status && currentUser?.job_title !== ADMIN_JOB_TITLES.LISTER,
      handleClick: (item: any) => dispatch(openRelistDialog(item.id)),
      fullWidth: true,
      conditionalDisable: () => !isAuctionActive
    },
    {
      title: 'Award',
      color: 'secondary',
      order: 9,
      conditionalRendering: (item: Vehicle) => {
        if (status === ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED && !vehicle.buyer_id) return false;
        return VEHICLE_STATUSES.ACTIVE === item.status && currentUser?.job_title !== ADMIN_JOB_TITLES.LISTER;
      },
      handleClick: (item: any) => {
        dispatch(modalsToggle('auctionDetailAwardBuyerModal'));
        dispatch(auctionVehiclesSetModal(item));
      },
      fullWidth: true
    }
  ];

  return (
    <Wrapper>
      {buttons
        .sort((a, b) => a.order - b.order)
        .map((btn, i) => (
          <TableButton key={`b${i}`} btn={btn} itemData={vehicle} />
        ))}
    </Wrapper>
  );
};
