export const VEHICLE_STATUSES = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  PULLED: 'pulled',
  AWARDED: 'awarded',
  DEACTIVATED: 'deactivated'
};

export const ADMIN_VEHICLE_STATUS = {
  ...VEHICLE_STATUSES,
  IN_PROGRESS: 'in_progress',
  SELLER_COUNTER_SENT: 'seller_counter_sent',
  ADMIN_COUNTER_SENT: 'admin_counter_sent',
  BUYER_COUNTER_SENT: 'buyer_counter_sent',
  SELLER_NOT_COUNTERED: 'seller_not_countered',
  SELLER_ACCEPTED: 'seller_accepted',
  UNDECIDED: 'undecided',
  BUYER_NOT_COUNTERED: 'buyer_not_countered',
  PUBLIC_ADMIN_COUNTER_SENT: 'public_admin_counter_sent',
  SELLER_COUNTERED: 'seller_countered'
};

export const SELLER_VEHICLE_STATUS = {
  ...VEHICLE_STATUSES,
  IN_PROGRESS: 'in_progress',
  COUNTER_SENT_TO_BUYERS: 'counter_sent_to_buyers',
  BUYER_COUNTER_SENT: 'buyer_counter_sent',
  NEED_SELLER_REVIEW: 'need_seller_review',
  PENDING: 'pending',
  PENDING_AUTOAXESS_REVIEW: 'pending_autoaxess_review',
  PENDING_ADMIN_APPROVAL: 'pending_admin_approval',
  ACCEPTED_OFFER_PENDING_ADMIN_APPROVAL: 'accepted_offer_pending_admin_approval',
  PUBLIC_COUNTER_SENT_TO_BUYERS: 'public_counter_sent_to_buyers'
};

export const BUYER_VEHICLE_STATUS = {
  ...VEHICLE_STATUSES,
  DECLINED: 'declined',
  AWARDED_NOT_CHARGED: 'awarded_not_charged',
  AWARDED_PENDING_CHARGE: 'awarded_pending_charge',
  UNDECIDED: 'undecided',
  COUNTERED: 'countered',
  BUYER_COUNTER_SENT: 'buyer_counter_sent',
  OUT_BID: 'out_bid',
  HIGH_BID: 'high_bid',
  SELLER_ACCEPTING_OFFERS: 'seller_accepting_offers',
  TOP_BUYER_SELLER_ACCEPTING_OFFER: 'top_buyer_seller_accepting_offers',
  // TODO: figure out this status
  PENDING_APPROVAL: 'pending_approval'
};

export const BUYER_VEHICLE_LABELS = {
  [BUYER_VEHICLE_STATUS.AWARDED]: {
    color: '#06A000',
    label: 'Awarded'
  },
  [BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED]: {
    color: '#06A000',
    label: 'Awarded - Card Declined'
  },
  [BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE]: {
    color: '#06A000',
    label: 'Awarded - Pending Charge'
  },
  [BUYER_VEHICLE_STATUS.PULLED]: {
    color: '#D50808',
    label: 'Pulled'
  },
  [BUYER_VEHICLE_STATUS.DECLINED]: {
    color: '#D50808',
    label: 'Declined'
  },
  [BUYER_VEHICLE_STATUS.UNDECIDED]: {
    color: '#E47E00',
    label: 'Undecided'
  },
  [BUYER_VEHICLE_STATUS.PENDING_APPROVAL]: {
    color: '#E47E00',
    label: 'Undecided'
  },
  [BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS]: {
    color: '#E47E00',
    label: 'Seller Accepting Offers'
  },
  [BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER]: {
    color: '#E47E00',
    label: 'Seller Accepting Offers'
  },
  [BUYER_VEHICLE_STATUS.COUNTERED]: {
    color: '#CA8D57',
    label: 'Countered'
  },
  [BUYER_VEHICLE_STATUS.OUT_BID]: {
    color: '#E47E00',
    label: 'OutBid'
  },
  [BUYER_VEHICLE_STATUS.HIGH_BID]: {
    color: '#06A000',
    label: 'Highest Bidder'
  },
  [BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT]: {
    color: '#CA8D57',
    label: 'Counter Sent to Seller'
  }
};

export const SELLER_VEHICLE_LABELS = {
  [SELLER_VEHICLE_STATUS.IN_PROGRESS]: {
    color: '#06A000',
    label: 'Active'
  },
  [SELLER_VEHICLE_STATUS.AWARDED]: {
    color: '#06A000',
    label: 'Awarded'
  },
  [SELLER_VEHICLE_STATUS.COUNTER_SENT_TO_BUYERS]: {
    color: '#E47E00',
    label: 'Counter Sent To Buyer'
  },
  [SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS]: {
    color: '#E47E00',
    label: 'Counter Sent To Buyers'
  },
  [SELLER_VEHICLE_STATUS.PULLED]: {
    color: '#D50808',
    label: 'Pulled'
  },
  [SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW]: {
    color: '#E47E00',
    label: 'Need Seller Review'
  },
  [SELLER_VEHICLE_STATUS.PENDING]: {
    color: '#E47E00',
    label: 'Pending'
  },
  [SELLER_VEHICLE_STATUS.PENDING_AUTOAXESS_REVIEW]: {
    color: '#E47E00',
    label: 'Pending Autoaxess Review'
  },
  [SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT]: {
    color: '#E47E00',
    label: 'Counter Sent to Seller'
  },
  [SELLER_VEHICLE_STATUS.PENDING_ADMIN_APPROVAL]: {
    color: '#e47E00',
    label: 'Pending Admin Approval'
  },
  [SELLER_VEHICLE_STATUS.ACCEPTED_OFFER_PENDING_ADMIN_APPROVAL]: {
    color: '#e47E00',
    label: 'Pending Admin Approval'
  }
};
